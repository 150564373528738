export const BOOKING_CONFLICTS_LIMIT = 50;
export const CUSTOM_DATERANGE = "CUSTOM";
export const MAX_MESSAGE_ATTACHMENTS = 9;
export const MAX_TAG_LENGTH = 20;

// Frequencies
export const DAILY_FREQUENCY = "localdate";
export const WEEKLY_FREQUENCY = "ywy";
export const MONTHLY_FREQUENCY = "ym";
export const QUARTERLY_FREQUENCY = "yq";
export const YEARLY_FREQUENCY = "year";

// Days of the week
export const MONDAY = "MONDAY";
export const TUESDAY = "TUESDAY";
export const WEDNESDAY = "WEDNESDAY";
export const THURSDAY = "THURSDAY";
export const FRIDAY = "FRIDAY";
export const SATURDAY = "SATURDAY";
export const SUNDAY = "SUNDAY";

// Resource Types
export const LOCATION_SERVICE_HOURS = "LOCATION_SERVICE_HOURS";
export const ALWAYS_OPEN = "ALWAYS_OPEN";
export const CUSTOM_HOURS = "CUSTOM_HOURS";

// Charts
export const MAIN_ACCOUNT_CHART = "MAIN_ACCOUNT_CHART";
export const ACTIVE_ACCOUNTS_CHART = "ACTIVE_ACCOUNTS_CHART";
export const NUMBER_OF_ACCOUNTS_CHART = "NUMBER_OF_ACCOUNTS_CHART";
export const NUMBER_OF_ACCOUNTS_ON_PLANS_CHART =
  "NUMBER_OF_ACCOUNTS_ON_PLANS_CHART";
export const NUMBER_OF_NEW_SUBSCRIPTIONS_CHART =
  "NUMBER_OF_NEW_SUBSCRIPTIONS_CHART";
export const ACCOUNT_GROWTH_CHART = "ACCOUNT_GROWTH_CHART";
export const PLAN_GROWTH_CHART = "PLAN_GROWTH_CHART";

export const REVENUE_BY_TYPE_CHART = "REVENUE_BY_TYPE_CHART";
export const REVENUE_BY_PLAN_CHART = "REVENUE_BY_PLAN_CHART";
export const REVENUE_BY_LOCATION_CHART = "REVENUE_BY_LOCATION_CHART";
export const REVENUE_BY_PAYMENT_CHART = "REVENUE_BY_PAYMENT_CHART";
export const REVENUE_GROWTH_CHART = "REVENUE_GROWTH_CHART";
export const FORECASTED_PLAN_REVENUE = "FORECASTED_PLAN_REVENUE";
export const MAIN_REVENUE_CHART = "MAIN_REVENUE_CHART";

export const NUMBER_OF_HOURS_BOOKED_CHART = "NUMBER_OF_HOURS_BOOKED_CHART";
export const AVG_DURATION_OF_BOOKINGS_CHART = "AVG_DURATION_OF_BOOKINGS_CHART";
export const NUMBER_OF_CHECKINS = "NUMBER_OF_CHECKINS";
export const RESOURCE_UTILIZATION_PCT = "RESOURCE_UTILIZATION_PCT";
export const MAIN_ACTIVITY_CHART = "MAIN_ACTIVITY_CHART";

// Mutations
export const MUTATE_LOCATIONS_FILTER = "MUTATE_LOCATIONS_FILTER";
export const MUTATE_ANALYTICS_STORE = "MUTATE_ANALYTICS_STORE";
export const MUTATE_INIT_ANALYTICS_STORE = "MUTATE_INIT_ANALYTICS_STORE";
export const MUTATE_FREQUENCY = "MUTATE_FREQUENCY";
export const MUTATE_DATE_RANGE = "MUTATE_DATE_RANGE";
export const MUTATE_FUTURE_DATE_RANGE = "MUTATE_FUTURE_DATE_RANGE";
export const MUTATE_PRIMARY_LOCATIONS = "MUTATE_PRIMARY_LOCATIONS";
export const MUTATE_DEMO = "MUTATE_DEMO";
export const MUTATE_WIZARD_PROGRESS = "MUTATE_WIZARD_PROGRESS";
export const MUTATE_WIZARD_META = "MUTATE_WIZARD_META";
export const MUTATE_WIZARD_LOADING = "MUTATE_WIZARD_LOADING";
export const MUTATE_WIZARD_BREADCRUMB = "MUTATE_WIZARD_BREADCRUMB";

// Wizard Resource Type
export const MUTATE_WIZARD_RESOURCE_TYPE = "MUTATE_WIZARD_RESOURCE_TYPE";
export const MUTATE_WIZARD_APP_LINK = "MUTATE_WIZARD_APP_LINK";

// Wizard Assignment
export const MUTATE_WIZARD_ASSIGNMENT = "MUTATE_WIZARD_ASSIGNMENT";
export const MUTATE_WIZARD_CONDITIONS = "MUTATE_WIZARD_CONDITIONS";
export const MUTATE_WIZARD_CONFLICTS = "MUTATE_WIZARD_CONFLICTS";

// Local Storage
export const LS_ANALYTICS_DATE_RANGE = "analytics_date_range";
export const LS_ANALYTICS_FUTURE_DATE_RANGE = "analytics_future_date_range";
export const LS_ANALYTICS_FREQUENCY = "analytics_frequency";
export const LS_PRIMARY_LOCATIONS = "analytics_primary_locations";

export const GOOGLE_MAPS_API_KEY = function() {
  let host = window.location.host;
  if (host.match(/optixapp\.com/)) {
    return "AIzaSyBnsJSsYKJ88acdacsHSa8vI40ylRZODWU";
  } else if (host.match(/optixdev\.com/)) {
    return "AIzaSyCjtPqdUseyvcsZKZTkZE1Bw2KF2tJ4EoA";
  }
  return "AIzaSyB10skF7f-BQy2gPYUmzbs_9i9OkPgIDM0";
};

/**
 * PostHog app keys can be found in (a) Project Settings
 * or (b) posthog > docs > "Install PostHog"
 */
export const POSTHOG_API_KEY = function() {
  let host = window.location.host;
  if (host.match(/optixapp\.com/)) {
    return "phc_4ZU3y3kDDmmjhTJMK3lUHqjsUYj8IochTBoUwrFFQC7";
  } else if (host.match(/optixdev\.com/)) {
    return "phc_IHoZPSpG4WmOZNGPSDBM8BslIIWaBskOwo3jLgbPM7K";
  }
  return null;
};
