import posthog from "posthog-js";
import { POSTHOG_API_KEY } from "@/constants";

export default {
  install(Vue) {
    const apiKey = POSTHOG_API_KEY();
    if (!apiKey) {
      return;
    }

    posthog.init(apiKey, {
      api_host: "https://us.i.posthog.com",
      disable_session_recording: true,
      session_recording: {
        recordCrossOriginIframes: true,
      },
      persistence: "cookie",
      autocapture: {
        // dom_event_allowlist: ['click', 'change', 'submit'],
        url_allowlist: [".*/admin/inbox.*"],
        // url_ignorelist: ["posthog.com./docs/.*/secret-section/.*"],
        // element_allowlist:['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
        // css_selector_allowlist: ["[ph-autocapture]"],
        // element_attribute_ignorelist: ['data-attr-pii="email"'],
      },
      capture_pageview: true,
      capture_pageleave: false,
      capture_performance: { web_vitals: false },
    });

    Vue.prototype.$posthog = posthog;
  },
};
