<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Environment from "./utils/Environment";
import LocationObserver from "./utils/LocationObserver";
import { initStorage } from "./utils/Storage";

export default {
  name: "App",
  async created() {
    this.$optix.canvas.startHandlingParentMessages();
    Environment.init(this.$optix);
    LocationObserver.init(this.$optix);
    initStorage(this.$optix.env.get.organization_id);
    this.$gtag.config({
      linker: {
        accept_incoming: true,
        domains: ["optixapp.com", "optixdev.com", "optixapp.local"],
      },
    });

    if (this.$optix.env.environment !== "local") {
      try {
        const organizationId = this.$optix.env.get.organization_id;
        const email = this.$optix.env.get.email;
        this.$posthog.group("organization", organizationId);
        this.$posthog.identify(email);
      } catch (e) {
        //
      }
    }
  },
};
</script>

<style lang="scss"></style>
